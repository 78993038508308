<template>
  <div class="ancvue-dropdown" ref="dropdownRef">
    <div class="dropdown-button" ref="dropdownButtonRef" @click.prevent="onButtonClick">
      <div v-if="selectedLabel" class="active-label" :style="labelStyle">
        {{ selectedLabel }}
      </div>
      <div v-else class="placeholder">选 择</div>
      <img class="arrow" :style="arrowStyle" src="@/assets/imgs/down-arrow.png" />
    </div>
    <div class="apple">
      <div v-if="isOpen" ref="dropdownPanelRef" class="dropdown-panel">
        <div
          class="option"
          ref="optionRef"
          :style="optionStyle"
          v-for="(item, index) in options"
          :key="index"
          :class="{ target: item.value == selectedValue }"
          @click="onOptionClick(item)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, nextTick, onMounted } from "vue";
import useClickOutside from "@/hooks/useClickOutside";

const props = defineProps({
  modelValue: {
    type: [Number, String, null, undefined],
    required: true,
    default: null,
  },
  options: {
    type: Array,
    default: () => [
      { label: "选项1", value: 1 },
      { label: "选项2", value: 2 },
      { label: "选项3", value: 3 },
    ],
  },
  centerValue: { type: [Number, String], default: 0 },
  setRed: { type: Boolean, default: false },
});
const emits = defineEmits(["change", "update:modelValue"]);

const selectedLabel = ref(null);
const selectedValue = ref(null);
const dropdownRef = ref();
const dropdownButtonRef = ref();
const dropdownPanelRef = ref();
const isOpen = ref(false);
const optionRef = ref(null);

// 双向绑定
watch(
  () => props.modelValue,
  val => {
    selectedValue.value = val;
    let ifHasValue = false;
    props.options.forEach(item => {
      if (item.value === val) {
        selectedLabel.value = item.label;
        ifHasValue = true;
      }
    });
    if (!ifHasValue) {
      selectedLabel.value = null;
    }
  },
  { immediate: true }
);

const optionStyle = computed(() => {
  let style = {};
  if (optionRef.value && optionRef.value[0]) {
    style.height = optionRef.value[0].offsetHeight + "px";
  }
  return style;
});

const labelStyle = computed(() => {
  let style = {};
  if (props.setRed) {
    style.color = "#ea3e3c";
  }
  return style;
});

// 判断是否点击在外部，关闭下拉框
const isClickOutside = useClickOutside(dropdownRef);
watch(isClickOutside, () => {
  if (isOpen.value && isClickOutside.value) {
    isOpen.value = false;
  }
});

// 右侧箭头方向样式
const arrowStyle = computed(() => ({
  transform: isOpen.value ? "rotate(-180deg)" : "none",
}));

// 当点击 button，展开或收起下拉框
const onButtonClick = () => {
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    setTimeout(() => {
      nextTick(() => {
        if (selectedValue.value === null) {
          if (props.centerValue) {
            // 如果设置了居中显示的选项的值
            let targetIndex;
            props.options.forEach((item, index) => {
              if (props.centerValue === item.value) {
                targetIndex = index;
              }
            });
            let totalHeight = 0;
            document.querySelectorAll(".option").forEach((item, index) => {
              if (index < targetIndex) {
                totalHeight += item.offsetHeight;
                // console.log(item.offsetHeight);
              }
            });
            document.querySelector(".dropdown-panel").scrollTop = totalHeight;
          } else {
            document.querySelector(".dropdown-panel").scrollTop = 0;
          }
        } else {
          let targetIndex;
          props.options.forEach((item, index) => {
            if (item.value === selectedValue.value) {
              targetIndex = index;
            }
          });
          let totalHeight = 0;
          document.querySelectorAll(".option").forEach((item, index) => {
            if (index < targetIndex - 2) {
              totalHeight += item.offsetHeight;
            }
          });
          document.querySelector(".dropdown-panel").scrollTop = totalHeight;
        }
      }, 0);
    });
  }
};

// 当选择选项
const onOptionClick = e => {
  isOpen.value = false;
  selectedLabel.value = e.label;
  selectedValue.value = e.value;
  emits("change", e);
  emits("update:modelValue", e.value);
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
@import "@/style/constants.scss";
@import "~@/assets/fonts/font.css";

.ancvue-dropdown {
  position: relative;
  .dropdown-button {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.8rem;
    box-shadow: 0 0 0 1px $border-color;
    border-radius: 0.7rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: $border-color;
    .arrow {
      width: 0.8rem;
      transition: 0.1s all;
    }
    .active-label {
      font-family: sourcehansans_medium;
      color: $primary-color;
    }
  }
  .dropdown-panel {
    position: absolute;
    top: 3.2rem;
    overflow: scroll;
    padding: 0.5rem 1rem;
    width: 100%;
    height: fit-content;
    max-height: 15.2rem; 
    background-color: #fff;
    box-shadow: 0 0 0 1px $border-color, 0 0.1rem 0.7rem 0.1rem rgba($color: #000000, $alpha: 0.05);
    border-radius: 0.7rem;
    z-index: 10000;
    .option {
      display: flex;
      align-items: center;
      height: 2.8rem;
      font-size: 1.2rem;
      font-family: sourcehansans_normal;
      color: #555;
    }
    .option.target {
      color: $primary-color;
      font-family: sourcehansans_medium;
    }
  }
}
</style>
