<template>
  <div
    class="ancvue-home"
    :class="$store.state.isPadHorizontal ? 'pad-horizontal' : 'phone'"
  >
    <div class="description">请输入单眼或双眼的验光/框架数据</div>
    <div class="clear-all">
      <!-- <div class="">* 必填项 |</div> -->
      <div
        ref="clearButtonRef"
        class="clear-button"
        :style="clearButtonStyle"
        @click="clearAll"
      >
        全部清除
      </div>
      <div v-if="noData" class="no-data">无匹配的产品可提供，建议调整。</div>
    </div>
    <div class="select-container">
      <!-- 球镜 -->
      <div class="select—wrapper">
        <div class="title">球镜</div>
        <div class="select-line">
          <div class="right-eye">
            <div class="right-eye-text">右眼</div>
            <dropdown
              v-if="!$store.state.isPadHorizontal"
              class="right-eye-dropdown"
              v-model="prescription.rightSphere"
              :options="sphereOptions"
              :center-value="-4.5"
            ></dropdown>
            <dropdown-pad-horizontal
              v-if="$store.state.isPadHorizontal"
              class="right-eye-dropdown"
              v-model="prescription.rightSphere"
              :options="sphereOptions"
              :center-value="-4.5"
            ></dropdown-pad-horizontal>
          </div>
          <div class="left-eye">
            <div class="left-eye-text">左眼</div>
            <dropdown
              v-if="!$store.state.isPadHorizontal"
              class="left-eye-dropdown"
              v-model="prescription.leftSphere"
              :options="sphereOptions"
              :center-value="-4.5"
            ></dropdown>
            <dropdown-pad-horizontal
              v-if="$store.state.isPadHorizontal"
              class="left-eye-dropdown"
              v-model="prescription.leftSphere"
              :options="sphereOptions"
              :center-value="-4.5"
            ></dropdown-pad-horizontal>
          </div>
        </div>
      </div>
      <!-- 柱镜 -->
      <div class="select—wrapper">
        <div class="title">柱镜</div>
        <div class="select-line">
          <div class="right-eye">
            <div class="right-eye-text">右眼</div>
            <dropdown
              v-if="!$store.state.isPadHorizontal"
              class="right-eye-dropdown"
              v-model="prescription.rightCylinder"
              :options="cylinderOptions"
              @change="onRightCylinderChange"
            ></dropdown>
            <dropdown-pad-horizontal
              v-if="$store.state.isPadHorizontal"
              class="right-eye-dropdown"
              v-model="prescription.rightCylinder"
              :options="cylinderOptions"
              @change="onRightCylinderChange"
            ></dropdown-pad-horizontal>
          </div>
          <div class="left-eye">
            <div class="left-eye-text">左眼</div>
            <dropdown
              v-if="!$store.state.isPadHorizontal"
              class="left-eye-dropdown"
              v-model="prescription.leftCylinder"
              :options="cylinderOptions"
              @change="onLeftCylinderChange"
            ></dropdown>
            <dropdown-pad-horizontal
              v-if="$store.state.isPadHorizontal"
              class="left-eye-dropdown"
              v-model="prescription.leftCylinder"
              :options="cylinderOptions"
              @change="onLeftCylinderChange"
            ></dropdown-pad-horizontal>
          </div>
        </div>
      </div>
      <!-- 轴位 -->
      <div class="select—wrapper" ref="selectWrapperRef">
        <div class="title">
          <span>轴位</span>
        </div>
        <div class="select-line">
          <div class="right-eye">
            <div class="right-eye-text">右眼</div>
            <dropdown
              v-if="!$store.state.isPadHorizontal"
              class="right-eye-dropdown"
              v-model="prescription.rightAxis"
              :options="axisOptions"
              @change="onRightAxisChange"
            ></dropdown>
            <dropdown-pad-horizontal
              v-if="$store.state.isPadHorizontal"
              class="right-eye-dropdown"
              v-model="prescription.rightAxis"
              :options="axisOptions"
              @change="onRightAxisChange"
            ></dropdown-pad-horizontal>
          </div>
          <div class="left-eye">
            <div class="left-eye-text">左眼</div>
            <dropdown
              v-if="!$store.state.isPadHorizontal"
              class="left-eye-dropdown"
              v-model="prescription.leftAxis"
              :options="axisOptions"
              @change="onLeftAxisChange"
            ></dropdown>
            <dropdown-pad-horizontal
              v-if="$store.state.isPadHorizontal"
              class="left-eye-dropdown"
              v-model="prescription.leftAxis"
              :options="axisOptions"
              @change="onLeftAxisChange"
            ></dropdown-pad-horizontal>
          </div>
        </div>
      </div>
    </div>
    <div class="distance-calc-container">
      <!-- top-distance -->
      <div class="distance—wrapper">
        <div class="distance-left">
          <div class="title">
            <span>顶点距离&nbsp;</span>
            <span class="star">*</span>
          </div>
          <div class="info-container">
            <div class="info" ref="distanceTooltipRef">
              <img src="@/assets/imgs/info.png" @click.prevent="onTopDistanceClick" />
              <!-- tooltip -->
              <div class="distance-tooltip" v-if="showDistanceTooltip">
                <div class="text">
                  <div>顶点距离是指从角膜的前表面到试镜架上矫正镜片后表面的距离。</div>
                </div>
                <div class="triangle"></div>
              </div>
            </div>
            <div class="right-padding"></div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="distance-right">
          <div class="left-padding"></div>
          <div class="disabled-select">
            <div class="text">12mm</div>
            <img class="arrow" src="@/assets/imgs/down-arror-disabled.png" />
          </div>
        </div>
      </div>
      <!-- calc button -->
      <div class="calc-button-wrapper" :style="calcButtonStyle">
        <div
          class="calculate-button"
          :class="{ disabled: !allowPush }"
          @click="onCalculate"
        >
          <span class="calculate-button-text" v-if="!loading">计算</span>
          <span class="calculate-button-text" v-if="loading">计算中</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  watch,
  computed,
  toRaw,
  getCurrentInstance,
} from "vue";
import dropdown from "@/components/dropdown.vue";
import dropdownPadHorizontal from "@/components/dropdown-pad-horizontal.vue";
import useClickOutside from "@/hooks/useClickOutside";
import { sphereOptions, cylinderOptions, axisOptions } from "@/js/home.js";
import axios from "axios";
import { showNotify } from "vant";
import { getEvenPx } from "@/js/getEvenPx";

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { nextTick } from "process";

const props = defineProps({});
const emits = defineEmits([]);
const router = useRouter();
const store = useStore();

const clearButtonRef = ref(null);

const distanceTooltipRef = ref();
const showDistanceTooltip = ref(false);
const allowPush = ref(true);
const selectWrapperRef = ref(null);
const selectWrapperWidth = ref(0);
const orientation = ref(0);
const noData = ref(false);
const loading = ref(false);
const isSetRedLeft = ref(false);
const isSetRedRight = ref(false);

// 用户选择的处方数据
const prescription = ref({
  leftSphere: null, // 左眼球镜
  rightSphere: null, // 右眼球镜
  leftCylinder: null, // 左眼柱镜
  rightCylinder: null, // 右眼柱镜
  leftAxis: null, // 左眼轴位
  rightAxis: null, // 右眼轴位
});

// 如果已选择过处方，从缓存获取已选处方
if (window.sessionStorage.getItem("prescription")) {
  prescription.value = JSON.parse(window.sessionStorage.getItem("prescription"));
}

// 监视 prescription 变化，将新值缓存，并且判断按钮是否禁用
watch(
  prescription.value,
  () => window.sessionStorage.setItem("prescription", JSON.stringify(prescription.value)), // 缓存prescription,
  { deep: true, immediate: true }
);

const onRightCylinderChange = e => {
  if (e.value === 0) prescription.value.rightAxis = 0;
};

const onLeftCylinderChange = e => {
  if (e.value === 0) prescription.value.leftAxis = 0;
};

const onRightAxisChange = e => {
  if (e.value === 0) prescription.value.rightCylinder = 0;
};

const onLeftAxisChange = e => {
  if (e.value === 0) prescription.value.leftCylinder = 0;
};

// 点击顶点距离的感叹号
const onTopDistanceClick = () => {
  showDistanceTooltip.value = true;
};
const isClickOutside = useClickOutside(distanceTooltipRef);
watch(isClickOutside, () => {
  if (showDistanceTooltip.value && isClickOutside.value) {
    showDistanceTooltip.value = false;
  }
});

const clearButtonStyle = computed(() => {
  let style = {};
  if (clearButtonRef.value) {
    style.height = getEvenPx(clearButtonRef.value.clientHeight) + "px";
  }
  return style;
});

const clearAll = () => {
  prescription.value.leftSphere = null;
  prescription.value.rightSphere = null;
  prescription.value.leftCylinder = null;
  prescription.value.rightCylinder = null;
  prescription.value.leftAxis = null;
  prescription.value.rightAxis = null;
};

const onCalculate = () => {
  noData.value = false;
  loading.value = true;
  console.log(prescription.value);

  // 如果两个眼睛都没选数据，则不跳转，相当于两个眼睛都没产品
  if (
    prescription.value.leftAxis === null &&
    prescription.value.leftCylinder === null &&
    prescription.value.leftSphere === null &&
    prescription.value.rightAxis === null &&
    prescription.value.rightCylinder === null &&
    prescription.value.rightSphere === null
  ) {
    noData.value = true;
    loading.value = false;
    window.localStorage.setItem("whitchEyeHasValue", "none");
    window.localStorage.setItem("whitchEyeDoesntHaveValue", "all");
    return;
  } else if (
    prescription.value.leftAxis === null &&
    prescription.value.leftCylinder === null &&
    prescription.value.leftSphere === null
  ) {
    window.localStorage.setItem("whitchEyeHasValue", "right");
    window.localStorage.setItem("whitchEyeDoesntHaveValue", "left");
  } else if (
    prescription.value.rightAxis === null &&
    prescription.value.rightCylinder === null &&
    prescription.value.rightSphere === null
  ) {
    window.localStorage.setItem("whitchEyeHasValue", "left");
    window.localStorage.setItem("whitchEyeDoesntHaveValue", "right");
  } else {
    window.localStorage.setItem("whitchEyeHasValue", "all");
    window.localStorage.setItem("whitchEyeDoesntHaveValue", "none");
  }

  axios({
    url: "/api/widgets/query_product_by_sku?authcode=CN20230209ASD43ADSCADZ",
    method: "post",
    data: {
      sphereLeft: prescription.value.leftSphere,
      sphereRight: prescription.value.rightSphere,
      cylinderLeft: prescription.value.leftCylinder || 0,
      cylinderRight: prescription.value.rightCylinder || 0,
      axisLeft: prescription.value.leftAxis || 0,
      axisRight: prescription.value.rightAxis || 0,
      productId: [0],
      lensDeflectionLeft: 0,
      lensDeflectionRight: 0,
    },
  })
    .then(res => {
      let data = res.data.data;
      if (data.length) {
        // 上面判断了两个眼睛都没选数据的情况，下面判断一只眼睛没选数据的情况。如果有某一个眼睛没选数据，相当于某个眼睛没产品。
        // 如果另一个眼睛也无产品，就不跳转
        data.forEach(item => {
          if (item.productId === 0) {
            if (item.eye === "Right") {
              // eye 等于 Right 代表右眼无产品，如果左眼未选数据，则不跳转
              if (
                prescription.value.leftAxis === null &&
                prescription.value.leftCylinder === null &&
                prescription.value.leftSphere === null
              ) {
                noData.value = true;
                return;
              }
            }
            if (item.eye === "Left") {
              if (
                prescription.value.rightAxis === null &&
                prescription.value.rightCylinder === null &&
                prescription.value.rightSphere === null
              ) {
                noData.value = true;
                return;
              }
            }
          }
        });

        if (noData.value) {
          loading.value = false;
          return;
        }

        // 如果 data 有长度，代表两只眼睛都有产品，或者一只有一只没有，允许跳转
        router.push({ name: "ProductList" });
        isSetRedRight.value = false;
        isSetRedLeft.value = false;
        loading.value = false;
      } else {
        // 如果 data 是空数组，代表没有产品
        noData.value = true;
        loading.value = false;
      }
    })
    .catch(() => {
      loading.value = false;
      showNotify({ message: "请求出错", background: "#dc4a3a" });
    });
};

// 监听屏幕旋转，适配 pad 横屏
const watchOrientation = () => {
  window.addEventListener("orientationchange", () => {
    orientation.value = window.orientation;
    if (
      selectWrapperRef.value &&
      selectWrapperRef.value.clientWidth &&
      selectWrapperRef.value.clientWidth != selectWrapperWidth.value
    ) {
      selectWrapperWidth.value = selectWrapperRef.value.offsetWidth;
    }
  });
};

// 首次加载计算 select-wrapper 宽度，防止首次加载是横屏监听不到 orientation
watch(selectWrapperRef, newVal => {
  if (store.state.isPadHorizontal) {
    selectWrapperWidth.value = newVal.clientWidth;
    // console.log(selectWrapperWidth.value);
  }
});

// 屏幕旋转时计算 select-wrapper 宽度，防止首次加载是竖屏宽度没有数值
watch(orientation, newVal => {
  if (store.state.isPadHorizontal) {
    nextTick(() => {
      const el = selectWrapperRef.value;
      selectWrapperWidth.value = el.clientWidth;
      // console.log(selectWrapperWidth.value);
    });
  }
});

// 当是 pad 横屏时，使用 calcButtonStyle
const calcButtonStyle = computed(() => {
  let style = {};
  if (selectWrapperWidth.value && store.state.isPadHorizontal) {
    style.width = selectWrapperWidth.value + "px";
  }
  return style;
});

/*禁止缩放safari浏览器*/
const disabledSafari = () => {
  var lastTouchEnd = 0;
  window.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  window.addEventListener(
    "touchend",
    function (event) {
      var now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );

  /* 阻止双指指掐放大*/
  window.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};

onMounted(() => {
  watchOrientation();
  window.sessionStorage.setItem("productInfo", JSON.stringify([]));
  window.sessionStorage.setItem("selectedProductId", JSON.stringify([0]));
  window.sessionStorage.setItem("leftFixAxisDeg", 0);
  window.sessionStorage.setItem("rightFixAxisDeg", 0);
  window.sessionStorage.setItem("isLeftEye", false);
});
</script>

<style lang="scss" scoped>
@import "@/style/home/phone.scss";
@import "@/style/home/pad.scss";
</style>
