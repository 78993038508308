import { ref, onMounted, onUnmounted } from "vue";

const useClickOutside = elementRef => {
  const isClickOutside = ref(false);

  const handler = e => {
    if (elementRef.value) {
      if (!elementRef.value.contains(e.target)) {
        isClickOutside.value = true;
      } else {
        isClickOutside.value = false;
      }
    }
  };

  onMounted(() => {
    document.addEventListener("click", handler);
    document.addEventListener("", handler);
  });

  onUnmounted(() => {
    document.removeEventListener("click", handler);
  });

  return isClickOutside;
};

export default useClickOutside;
