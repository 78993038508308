// 球镜数据
export const sphereOptions = [
  { label: "-12.00", value: -12.0 },
  { label: "-11.75", value: -11.75 },
  { label: "-11.50", value: -11.5 },
  { label: "-11.25", value: -11.25 },
  { label: "-11.00", value: -11.0 },
  { label: "-10.75", value: -10.75 },
  { label: "-10.50", value: -10.5 },
  { label: "-10.25", value: -10.25 },
  { label: "-10.00", value: -10.0 },
  { label: "-9.75", value: -9.75 },
  { label: "-9.50", value: -9.5 },
  { label: "-9.25", value: -9.25 },
  { label: "-9.00", value: -9.0 },
  { label: "-8.75", value: -8.75 },
  { label: "-8.50", value: -8.5 },
  { label: "-8.25", value: -8.25 },
  { label: "-8.00", value: -8.0 },
  { label: "-7.75", value: -7.75 },
  { label: "-7.50", value: -7.5 },
  { label: "-7.25", value: -7.25 },
  { label: "-7.00", value: -7.0 },
  { label: "-6.75", value: -6.75 },
  { label: "-6.50", value: -6.5 },
  { label: "-6.25", value: -6.25 },
  { label: "-6.00", value: -6.0 },
  { label: "-5.75", value: -5.75 },
  { label: "-5.50", value: -5.5 },
  { label: "-5.25", value: -5.25 },
  { label: "-5.00", value: -5.0 },
  { label: "-4.75", value: -4.75 },
  { label: "-4.50", value: -4.5 },
  { label: "-4.25", value: -4.25 },
  { label: "-4.00", value: -4.0 },
  { label: "-3.75", value: -3.75 },
  { label: "-3.50", value: -3.5 },
  { label: "-3.25", value: -3.25 },
  { label: "-3.00", value: -3.0 },
  { label: "-2.75", value: -2.75 },
  { label: "-2.50", value: -2.5 },
  { label: "-2.25", value: -2.25 },
  { label: "-2.00", value: -2.0 },
  { label: "-1.75", value: -1.75 },
  { label: "-1.50", value: -1.5 },
  { label: "-1.25", value: -1.25 },
  { label: "-1.00", value: -1.0 },
  { label: "-0.75", value: -0.75 },
  { label: "-0.50", value: -0.5 },
  { label: "-0.25", value: -0.25 },
  { label: "0.00 ", value: 0.0 },
  { label: "0.25 ", value: 0.25 },
  { label: "0.50 ", value: 0.5 },
  { label: "0.75 ", value: 0.75 },
  { label: "1.00 ", value: 1.0 },
  { label: "1.25 ", value: 1.25 },
  { label: "1.50 ", value: 1.5 },
  { label: "1.75 ", value: 1.75 },
  { label: "2.00 ", value: 2.0 },
  { label: "2.25 ", value: 2.25 },
  { label: "2.50 ", value: 2.5 },
  { label: "2.75 ", value: 2.75 },
  { label: "3.00 ", value: 3.0 },
  { label: "3.25 ", value: 3.25 },
  { label: "3.50 ", value: 3.5 },
  { label: "3.75 ", value: 3.75 },
  { label: "4.00 ", value: 4.0 },
  { label: "4.25 ", value: 4.25 },
  { label: "4.50 ", value: 4.5 },
  { label: "4.75 ", value: 4.75 },
  { label: "5.00 ", value: 5.0 },
  { label: "5.25 ", value: 5.25 },
  { label: "5.50 ", value: 5.5 },
  { label: "5.75 ", value: 5.75 },
];

// 柱镜数据
export const cylinderOptions = [
  { label: "-3.50", value: -3.5 },
  { label: "-3.25", value: -3.25 },
  { label: "-3.00", value: -3.0 },
  { label: "-2.75", value: -2.75 },
  { label: "-2.50", value: -2.5 },
  { label: "-2.25", value: -2.25 },
  { label: "-2.00", value: -2.0 },
  { label: "-1.75", value: -1.75 },
  { label: "-1.50", value: -1.5 },
  { label: "-1.25", value: -1.25 },
  { label: "-1.00", value: -1.0 },
  { label: "-0.75", value: -0.75 },
  { label: "-0.50", value: -0.5 },
  { label: "-0.25", value: -0.25 },
  { label: "NA", value: 0 },
];

// export const axisOptions = [
//   { label: "0°", value: 0 },
//   { label: "1°", value: 1 },
//   { label: "2°", value: 2 },
//   { label: "3°", value: 3 },
//   { label: "4°", value: 4 },
//   { label: "5°", value: 5 },
//   { label: "6°", value: 6 },
//   { label: "7°", value: 7 },
//   { label: "8°", value: 8 },
//   { label: "9°", value: 9 },
//   { label: "10°", value: 10 },
//   { label: "11°", value: 11 },
//   { label: "12°", value: 12 },
//   { label: "13°", value: 13 },
//   { label: "14°", value: 14 },
//   { label: "15°", value: 15 },
//   { label: "16°", value: 16 },
//   { label: "17°", value: 17 },
//   { label: "18°", value: 18 },
//   { label: "19°", value: 19 },
//   { label: "20°", value: 20 },
//   { label: "21°", value: 21 },
//   { label: "22°", value: 22 },
//   { label: "23°", value: 23 },
//   { label: "24°", value: 24 },
//   { label: "25°", value: 25 },
//   { label: "26°", value: 26 },
//   { label: "27°", value: 27 },
//   { label: "28°", value: 28 },
//   { label: "29°", value: 29 },
//   { label: "30°", value: 30 },
//   { label: "31°", value: 31 },
//   { label: "32°", value: 32 },
//   { label: "33°", value: 33 },
//   { label: "34°", value: 34 },
//   { label: "35°", value: 35 },
//   { label: "36°", value: 36 },
//   { label: "37°", value: 37 },
//   { label: "38°", value: 38 },
//   { label: "39°", value: 39 },
//   { label: "40°", value: 40 },
//   { label: "41°", value: 41 },
//   { label: "42°", value: 42 },
//   { label: "43°", value: 43 },
//   { label: "44°", value: 44 },
//   { label: "45°", value: 45 },
//   { label: "46°", value: 46 },
//   { label: "47°", value: 47 },
//   { label: "48°", value: 48 },
//   { label: "49°", value: 49 },
//   { label: "50°", value: 50 },
//   { label: "51°", value: 51 },
//   { label: "52°", value: 52 },
//   { label: "53°", value: 53 },
//   { label: "54°", value: 54 },
//   { label: "55°", value: 55 },
//   { label: "56°", value: 56 },
//   { label: "57°", value: 57 },
//   { label: "58°", value: 58 },
//   { label: "59°", value: 59 },
//   { label: "60°", value: 60 },
//   { label: "61°", value: 61 },
//   { label: "62°", value: 62 },
//   { label: "63°", value: 63 },
//   { label: "64°", value: 64 },
//   { label: "65°", value: 65 },
//   { label: "66°", value: 66 },
//   { label: "67°", value: 67 },
//   { label: "68°", value: 68 },
//   { label: "69°", value: 69 },
//   { label: "70°", value: 70 },
//   { label: "71°", value: 71 },
//   { label: "72°", value: 72 },
//   { label: "73°", value: 73 },
//   { label: "74°", value: 74 },
//   { label: "75°", value: 75 },
//   { label: "76°", value: 76 },
//   { label: "77°", value: 77 },
//   { label: "78°", value: 78 },
//   { label: "79°", value: 79 },
//   { label: "80°", value: 80 },
//   { label: "81°", value: 81 },
//   { label: "82°", value: 82 },
//   { label: "83°", value: 83 },
//   { label: "84°", value: 84 },
//   { label: "85°", value: 85 },
//   { label: "86°", value: 86 },
//   { label: "87°", value: 87 },
//   { label: "88°", value: 88 },
//   { label: "89°", value: 89 },
//   { label: "90°", value: 90 },
//   { label: "91°", value: 91 },
//   { label: "92°", value: 92 },
//   { label: "93°", value: 93 },
//   { label: "94°", value: 94 },
//   { label: "95°", value: 95 },
//   { label: "96°", value: 96 },
//   { label: "97°", value: 97 },
//   { label: "98°", value: 98 },
//   { label: "99°", value: 99 },
//   { label: "100°", value: 100 },
//   { label: "101°", value: 101 },
//   { label: "102°", value: 102 },
//   { label: "103°", value: 103 },
//   { label: "104°", value: 104 },
//   { label: "105°", value: 105 },
//   { label: "106°", value: 106 },
//   { label: "107°", value: 107 },
//   { label: "108°", value: 108 },
//   { label: "109°", value: 109 },
//   { label: "110°", value: 110 },
//   { label: "111°", value: 111 },
//   { label: "112°", value: 112 },
//   { label: "113°", value: 113 },
//   { label: "114°", value: 114 },
//   { label: "115°", value: 115 },
//   { label: "116°", value: 116 },
//   { label: "117°", value: 117 },
//   { label: "118°", value: 118 },
//   { label: "119°", value: 119 },
//   { label: "120°", value: 120 },
//   { label: "121°", value: 121 },
//   { label: "122°", value: 122 },
//   { label: "123°", value: 123 },
//   { label: "124°", value: 124 },
//   { label: "125°", value: 125 },
//   { label: "126°", value: 126 },
//   { label: "127°", value: 127 },
//   { label: "128°", value: 128 },
//   { label: "129°", value: 129 },
//   { label: "130°", value: 130 },
//   { label: "131°", value: 131 },
//   { label: "132°", value: 132 },
//   { label: "133°", value: 133 },
//   { label: "134°", value: 134 },
//   { label: "135°", value: 135 },
//   { label: "136°", value: 136 },
//   { label: "137°", value: 137 },
//   { label: "138°", value: 138 },
//   { label: "139°", value: 139 },
//   { label: "140°", value: 140 },
//   { label: "141°", value: 141 },
//   { label: "142°", value: 142 },
//   { label: "143°", value: 143 },
//   { label: "144°", value: 144 },
//   { label: "145°", value: 145 },
//   { label: "146°", value: 146 },
//   { label: "147°", value: 147 },
//   { label: "148°", value: 148 },
//   { label: "149°", value: 149 },
//   { label: "150°", value: 150 },
//   { label: "151°", value: 151 },
//   { label: "152°", value: 152 },
//   { label: "153°", value: 153 },
//   { label: "154°", value: 154 },
//   { label: "155°", value: 155 },
//   { label: "156°", value: 156 },
//   { label: "157°", value: 157 },
//   { label: "158°", value: 158 },
//   { label: "159°", value: 159 },
//   { label: "160°", value: 160 },
//   { label: "161°", value: 161 },
//   { label: "162°", value: 162 },
//   { label: "163°", value: 163 },
//   { label: "164°", value: 164 },
//   { label: "165°", value: 165 },
//   { label: "166°", value: 166 },
//   { label: "167°", value: 167 },
//   { label: "168°", value: 168 },
//   { label: "169°", value: 169 },
//   { label: "170°", value: 170 },
//   { label: "171°", value: 171 },
//   { label: "172°", value: 172 },
//   { label: "173°", value: 173 },
//   { label: "174°", value: 174 },
//   { label: "175°", value: 175 },
//   { label: "176°", value: 176 },
//   { label: "177°", value: 177 },
//   { label: "178°", value: 178 },
//   { label: "179°", value: 179 },
//   { label: "180°", value: 180 },
// ];

export const axisOptions = [
  { label: "NA", value: 0 },
  { label: "5°", value: 5 },
  { label: "10°", value: 10 },
  { label: "15°", value: 15 },
  { label: "20°", value: 20 },
  { label: "25°", value: 25 },
  { label: "30°", value: 30 },
  { label: "35°", value: 35 },
  { label: "40°", value: 40 },
  { label: "45°", value: 45 },
  { label: "50°", value: 50 },
  { label: "55°", value: 55 },
  { label: "60°", value: 60 },
  { label: "65°", value: 65 },
  { label: "70°", value: 70 },
  { label: "75°", value: 75 },
  { label: "80°", value: 80 },
  { label: "85°", value: 85 },
  { label: "90°", value: 90 },
  { label: "95°", value: 95 },
  { label: "100°", value: 100 },
  { label: "105°", value: 105 },
  { label: "110°", value: 110 },
  { label: "115°", value: 115 },
  { label: "120°", value: 120 },
  { label: "125°", value: 125 },
  { label: "130°", value: 130 },
  { label: "135°", value: 135 },
  { label: "140°", value: 140 },
  { label: "145°", value: 145 },
  { label: "150°", value: 150 },
  { label: "155°", value: 155 },
  { label: "160°", value: 160 },
  { label: "165°", value: 165 },
  { label: "170°", value: 170 },
  { label: "175°", value: 175 },
  { label: "180°", value: 180 },
];
